import { User } from "../desktop/management/users/users.interfaces";

export interface SelectOption {
  label: string;
  value: any;
  type?: any;
}
export interface SelectGroup {
  label: string;
  options: SelectOption[];
}

export interface CasaFile {
  id?: number;
  url: string;
  name?: string;
  type?: string;
  _updated?: boolean;
}
export interface HistoryRecord {
  id: number;
  module: CasaModule;
  user: User;
  title: string;
  description: string;
  creation: Date;
  relation_id?: number;
}

export enum CasaModule {
  APP = 'app',
  MANAGEMENT = 'management',
  TASKS = 'tasks',
  PROFILE = 'profile',
  GAS = 'gas',
  RECEIPTS = 'receipts',
  FORMS = 'forms',
  LEAVES = 'leaves',
  MEETINGS = 'meetings',
  KPIS = 'kpis',
  STROS = 'stros',
  GBU = 'gbu',
  KB = 'kb'
}

export enum CasaEntity {
  GENERAL = 'general',
  USER = 'user',
  JOB = 'job',
  BRANCH = 'branch',
  TEAM = 'team',
  PROJECT = 'project',
  HISTORY = 'history',
  TASK = 'task',
  GAS = 'gas',
  VEHICLE = 'vehicle',
  RECEIPT = 'receipt',
  RECEIPT_PHOTO = 'receipt_photo',
  RECEIPT_COMMENT = 'receipt_comment',
  GENERAL_OPTION = 'general_option',
  FORM = 'form',
  FORM_REQUEST = 'form_request',
  LEAVE = 'leave',
  KPI = 'kpi',
  KPI_BOARD = 'kpi_board',
  MEETING_SCHEDULE = 'meeting_schedule',
  MEETING_SCHEDULED = 'meeting_scheduled',
  GOAL_ROADMAP = 'goal_roadmap',
  GBU = 'gbu',
  GBU_REPORT = 'gbu_report',
  GBU_FIELD = 'gbu_field',
  GBU_RESPONSE = 'gbu_response',
  KB_ARTICLE = 'kb_article',
  KB_CATEGORY = 'kb_category',
  KB_ATTACHMENT = 'kb_attachment'
}

export enum GeneralStatus {
  INACTIVE = 'inactive',
  ACTIVE = 'active'
}

export enum GeneralProgress {
  PENDING = 'pending',
  COMPLETED = 'completed'
}

export enum GeneralApproval {
  UNAPPROVED = 'unapproved',
  APPROVED = 'approved'
}

export enum GeneralSubmitStatus {
  PENDING = 'pending',
  DRAFT = 'draft',
  SUBMITTED = 'submitted'
}

export enum GeneralTransaction {
  DEPOSIT = 'deposit',
  CHARGE = 'charge'
}
export interface GeneralOption {
  id: number;
  relation_id?: number;
  ikey: string;
  value?: string;
  meta?: string;
  module: CasaModule;
}

export interface SubmenuOption {
  icon: any;
  link: string[];
  name: string;
  fontAwesome?: boolean;
  alwaysShown?: boolean;
}

export const MonthsArr = [
  { month: 1, name: "Jan" },
  { month: 2, name: "Feb" },
  { month: 3, name: "Mar" },
  { month: 4, name: "Apr" },
  { month: 5, name: "May" },
  { month: 6, name: "Jun" },
  { month: 7, name: "Jul" },
  { month: 8, name: "Aug" },
  { month: 9, name: "Sep" },
  { month: 10, name: "Oct" },
  { month: 11, name: "Nov" },
  { month: 12, name: "Dec" }
];

export const MonthsLabels: {[key: number]: string} = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December"
};

export const MOMENT_FORMAT_DATE_READABLE = "MMM DD YYYY";
export const MOMENT_FORMAT_DATE = "YYYY-MM-DD";
export const MOMENT_FORMAT_DATE_FULL = "YYYY-MM-DD HH:mm:ss";
export const MOMENT_FORMAT_DATE_FULL_READABLE = "MMM dd YYYY HH:mm:ss";
export const MOMENT_TIMESTAMP = "YYYYMMDDHHmmss";
export const LAST_VERSION = "Thrawn v1.051";
export const LAST_VERSION_MOBILE = "Thrawn v1.045";
