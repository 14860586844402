import { Branch, Job, UserRole } from "../desktop/management/users/users.interfaces"

export interface LoggedUser {
  id: number;
  name: string;
  email: string;
  role: UserRole;
  job: Job;
  token: string;
  branches: Branch[];
  access?: string[];
  special_access?: string[];
  photo?: string;
  fcm_token?: string;
  hasTeams: boolean;
  isFake?: boolean;
  shortName?: string;
}

export enum AuthTokenType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}
